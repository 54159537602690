import React, { Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import App from 'App'
import SEO from 'components/SEO'
import { Box, Flex } from 'components/Layout'
import { H1, H2, Text } from 'components/Typography'
import Button from 'components/Button'
import Container from 'components/Container'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import ExternalLink from 'components/Link'
import bgImage from 'static/images/sendible/bg_image.svg'
import partnershipProgramBackground from 'static/images/sendible/partnership_program_background.svg'
import socialMediaManagementPlatformImage from 'static/images/sendible/social_media_management_platform_image.png'
import toolsPartnerProgram from 'static/images/home/looking_for_a_marketing_agency_image.svg'
import check from 'static/images/sendible/check.svg'
import badge from 'static/images/sendible/badge.svg'
import AgenciesCounter from 'components/AgenciesCounter'
import PopularAgenciesCard from './Home/PopularAgenciesCard'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const BgImg = styled.img`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  object-fit: cover;
  z-index: -1;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
`

const BgImgPartnership = styled(BgImg)`
  left: -280px;
  object-fit: fill;
  z-index: 0;
`

const StyledImage = styled.img`
  pointer-events: none;
  max-width: 100%;
  object-fit: contain;
  z-index: 1;
`

const EXCEL = [
  'Excel in employing the best social media strategies for the clients. ',
  'Demonstrated ability to effectively manage their client’s social media by providing content that creates engagement and by growing the client’s social media following.',
  'Have a proven track record of customer social media success.',
  'Have in-depth expertise and knowledge of all existing social media networks and how to turn them into effective marketing tools for their clients.',
]

const BENEFITS = [
  'Client portfolio that highlights key customer success stories',
  'Connected social profiles offer a unified view of the latest news about an agency.',
  'Team profiles offer prospective clients additional confidence.',
  'Connect with future customers by receiving direct qualified leads from business searching for agencies.',
  'Get awesome SEO benefits by having superio online presence that is power charged for top ranking keywords.',
  'Agency Vista Verified badge that is awarded to all agencies that have completed their setup fully.',
]

const Partnership = ({ pageContext: { agencies = [], partnerId } }) => {
  let popularAgencies = [...agencies]
  if (popularAgencies.length > 10) {
    popularAgencies = agencies.slice(0, 10)
  }

  return (
    <App>
      <SEO
        title="Sendible Agency Partner Program"
        description="Grow your portfolio with ease by taking advantage of Sendible partnership with Agency Vista, an online directory that matches agencies with ideal clients!"
        path="/sendible/"
        image="/img/sendible_agnecy_partner_badge.png"
      />
      <Wrapper height={{ mobile: '600px', tablet: '650px', desktop: '750px' }}>
        <BgImg src={bgImage} />
        <Flex mt="xxl" flexDirection="column" justifyContent="center" alignItems="center" px="l" height="100%">
          <Box mt="xl" maxWidth="600px">
            <Text fontSize="xxxxl" lineHeight="m" fontWeight="bold">
              Sendible Agency Partner Program
            </Text>
          </Box>
          <Box maxWidth="500px">
            <Text fontSize="l" mt="m" color="secondaryText">
              Sendible Agency Partner Program includes digital marketing agencies that have excelled in using Sendible
              Social Media Management platform and have a proven track record of delivering social media results for
              their clients.
            </Text>
          </Box>
        </Flex>
      </Wrapper>
      <Container maxWidth="1192px">
        <Flex
          flexDirection={['column', 'row']}
          justifyContent={{ mobile: 'center', desktop: 'space-between' }}
          alignItems="center"
          flexWrap="wrap"
          mt="xl"
        >
          <Flex order={[1, 1]} flexDirection="column" maxWidth={{ mobile: '100%', desktop: '500px' }}>
            <Flex flexDirection="column">
              <H2 fontWeight="bold">Sendible Social Media Management Platform</H2>
              <Box mt="m" axWidth={{ mobile: '100%', desktop: '660px' }}>
                <Text mt="xs" fontSize="l" color="primaryText" opacity={0.8}>
                  Sendible is an all in one social media management platform that is loved by thousands of digital
                  marketers around the world.{' '}
                  <a href="https://agencyvista.com/hub/sendible/partners">Sendible Agency Partners</a> are certified to
                  have the following skills and achievements:
                </Text>
              </Box>
              <Flex mt="l" flexDirection="column">
                {EXCEL.map((item) => (
                  <Flex my="s" key={item} width="100%" alignItems="center">
                    <LocalImage source={check} width="12px" height="12px" />
                    <Text ml="m" color="primaryText" fontSize="m" opacity={0.8}>
                      {item}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </Flex>
          <Flex
            width="100%"
            maxWidth="500px"
            order={[2, 2]}
            mt={['l', '0']}
            justifyContent="center"
            position="relative"
          >
            <StyledImage
              src={socialMediaManagementPlatformImage}
              alt="Sendible Social Media Management Platform"
              width="100%"
              height="100%"
            />
          </Flex>
        </Flex>

        <Box mt="xxl">
          <Flex
            flexDirection={['column', 'row']}
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            alignItems="center"
            flexWrap="wrap"
          >
            <Flex order={[1, 1]} flexDirection="column" maxWidth={{ mobile: '100%', desktop: '500px' }}>
              <H2 fontWeight="bold">Agency Vista Partner Program</H2>
              <Text mt="l" mb="s" color="primaryText" fontSize="l" opacity={0.8} lineHeight="xl">
                Agency Vista is the largest network of verified digital marketing agencies. Agency Vista’s main goal is
                to bring out the best in agencies and to connect them with the most qualified new customers.
                Verification is at the root of everything we do. We verify every detail about agencies - from their
                social media presence and clients, to their team and reviews. At the same time we ensure that prospects
                are vetted as well before they are connected with agencies.
              </Text>
            </Flex>
            <Flex
              width="100%"
              maxWidth="500px"
              order={[2, 2]}
              mt={['l', '0']}
              justifyContent="center"
              position="relative"
            >
              <StyledImage
                src={toolsPartnerProgram}
                alt="Agency Vista for Tools Partner Program"
                width="100%"
                height="100%"
              />
            </Flex>
          </Flex>
        </Box>

        <Flex mt="xl" pt={{ mobile: '0', tablet: 'xxl' }} flexDirection="column" position="relative">
          <BgImgPartnership src={partnershipProgramBackground} />
          <Flex
            flexDirection={['column', 'row']}
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            alignItems="center"
            flexWrap="wrap"
          >
            <Flex order={[2, 1]} flexDirection="column" maxWidth={{ mobile: '100%', desktop: '750px' }}>
              <Box mt="m" maxWidth="620px">
                <Text mt="xs" fontSize="l" color="primaryText" opacity={0.8}>
                  Agency Vista enables platforms such as <a href="/hub/sendible">Sendible</a> to launch partnership
                  programs for their marketing agency customers and award them with verifiable badges. When the agencies
                  join this program they also join a growing ecosystem of <AgenciesCounter as="span" color="primary" />{' '}
                  other agencies on Agency Vista. These agencies then get to build up their profile beyond just a
                  partner badge. Some of the benefits of having an Agency Vista profile are:
                </Text>
              </Box>
              <Flex mt="l" flexDirection="column">
                {BENEFITS.map((item) => (
                  <Flex my="s" key={item} width="100%" alignItems="center">
                    <LocalImage source={check} width="12px" height="12px" />
                    <Text ml="m" color="primaryText" fontSize="m" opacity={0.8}>
                      {item}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </Flex>
            <Flex
              width="100%"
              maxWidth={{ mobile: '180px', desktop: '250px' }}
              order={[1, 2]}
              mt={{ mobile: 'l', desktop: '0' }}
              justifyContent="center"
              position="relative"
            >
              <StyledImage src={badge} alt="Sendible Social Media Management Platform" width="100%" height="100%" />
            </Flex>
          </Flex>
        </Flex>

        <Flex justifyContent="center" mt="m" mb="xxl" alignItems="center">
          <Button.Primary
            as={ExternalLink}
            href="https://www.sendible.com/agencyvista?utm_source=agencyvista.com"
            target="_blank"
          >
            Claim your Sendible Partner Badge
          </Button.Primary>
        </Flex>
      </Container>

      {popularAgencies.length > 0 && (
        <Fragment>
          <Flex justifyContent="center" mt="m" alignItems="center">
            <H2>Meet Sendible Agency Partners</H2>
          </Flex>
          <Flex mt="m" px="l" justifyContent="center" flexWrap="wrap">
            {popularAgencies.map((item) => (
              <PopularAgenciesCard key={item.agency_gid} {...item} />
            ))}
          </Flex>
        </Fragment>
      )}
      {partnerId && (
        <Fragment>
          <Flex justifyContent="center" mt="m" mb="xxl" alignItems="center">
            <Button.Primary as={ExternalLink} href={`/search?partnershipIds=${partnerId}&sortBy=1`} target="_blank">
              Find more Sendible Partners
            </Button.Primary>
          </Flex>
        </Fragment>
      )}
      <Footer />
    </App>
  )
}

Partnership.propTypes = {
  pageContext: PropTypes.shape({
    agencies: PropTypes.array,
    partnerId: PropTypes.number,
  }).isRequired,
}

export default Partnership
